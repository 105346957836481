import { default as abonnement_45geactiveerdEIZYo1WeVWMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/abonnement-geactiveerd.vue?macro=true";
import { default as abonnement_45geannuleerdrinwfkuWkAMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/abonnement-geannuleerd.vue?macro=true";
import { default as abonnement_45samenstellenbXVP6qZkwvMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/abonnement-samenstellen.vue?macro=true";
import { default as abonnementdV5xfX9jPdMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/abonnement.vue?macro=true";
import { default as abonnementb9xGCOoOUmMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/account/abonnement.vue?macro=true";
import { default as adresI2ToGAlp1bMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/account/adres.vue?macro=true";
import { default as gegevens6NOKlmApN5Meta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/account/gegevens.vue?macro=true";
import { default as indexrtyxmgHb4RMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/account/index.vue?macro=true";
import { default as resetwachtwoordUY6dHryaUrMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/account/resetwachtwoord.vue?macro=true";
import { default as wachtwoord_45wijzigenKXkDKE0mL3Meta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/account/wachtwoord-wijzigen.vue?macro=true";
import { default as activeren78fN24jijCMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/activeren.vue?macro=true";
import { default as afrekenenkYu2KHyYiNMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/afrekenen.vue?macro=true";
import { default as algemenevoorwaardentu1HP7ey5GMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/algemenevoorwaarden.vue?macro=true";
import { default as contact84mdy0PDvLMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/contact.vue?macro=true";
import { default as index9KdP62S0m1Meta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/index.vue?macro=true";
import { default as inloggenZyOgBWartWMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/inloggen.vue?macro=true";
import { default as overonsjAO5cIxagIMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/overons.vue?macro=true";
import { default as registrerenc7TRlIHk9CMeta } from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/registreren.vue?macro=true";
export default [
  {
    name: "abonnement-geactiveerd",
    path: "/abonnement-geactiveerd",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/abonnement-geactiveerd.vue").then(m => m.default || m)
  },
  {
    name: "abonnement-geannuleerd",
    path: "/abonnement-geannuleerd",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/abonnement-geannuleerd.vue").then(m => m.default || m)
  },
  {
    name: "abonnement-samenstellen",
    path: "/abonnement-samenstellen",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/abonnement-samenstellen.vue").then(m => m.default || m)
  },
  {
    name: "abonnement",
    path: "/abonnement",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/abonnement.vue").then(m => m.default || m)
  },
  {
    name: "account-abonnement",
    path: "/account/abonnement",
    meta: abonnementb9xGCOoOUmMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/account/abonnement.vue").then(m => m.default || m)
  },
  {
    name: "account-adres",
    path: "/account/adres",
    meta: adresI2ToGAlp1bMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/account/adres.vue").then(m => m.default || m)
  },
  {
    name: "account-gegevens",
    path: "/account/gegevens",
    meta: gegevens6NOKlmApN5Meta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/account/gegevens.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: indexrtyxmgHb4RMeta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-resetwachtwoord",
    path: "/account/resetwachtwoord",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/account/resetwachtwoord.vue").then(m => m.default || m)
  },
  {
    name: "account-wachtwoord-wijzigen",
    path: "/account/wachtwoord-wijzigen",
    meta: wachtwoord_45wijzigenKXkDKE0mL3Meta || {},
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/account/wachtwoord-wijzigen.vue").then(m => m.default || m)
  },
  {
    name: "activeren",
    path: "/activeren",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/activeren.vue").then(m => m.default || m)
  },
  {
    name: "afrekenen",
    path: "/afrekenen",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/afrekenen.vue").then(m => m.default || m)
  },
  {
    name: "algemenevoorwaarden",
    path: "/algemenevoorwaarden",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/algemenevoorwaarden.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inloggen",
    path: "/inloggen",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/inloggen.vue").then(m => m.default || m)
  },
  {
    name: "overons",
    path: "/overons",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/overons.vue").then(m => m.default || m)
  },
  {
    name: "registreren",
    path: "/registreren",
    component: () => import("/home/cleavr/versvandevelde.nl/releases/20250125095238118/pages/registreren.vue").then(m => m.default || m)
  }
]