import revive_payload_client_4sVQNw7RlN from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_1doCGnr5OF from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import mitt_S0QU5gJPTl from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/plugins/mitt.ts";
import useEmitter_8q5Lrsmm6i from "/home/cleavr/versvandevelde.nl/releases/20250125095238118/plugins/useEmitter.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_1doCGnr5OF,
  mitt_S0QU5gJPTl,
  useEmitter_8q5Lrsmm6i
]